import React from "react";

export default function View({ className, children }) {
  return (
    <div
      className={`overthrow container py-3 ${className}`}
      style={{ height: "calc(100vh - 105px)", marginTop: "45px" }}
    >
      {children}
    </div>
  );
}
