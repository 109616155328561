import React from "react";
import { Card, CardBody } from "reactstrap";

import VoucherHeader from "./VoucherHeader";

export default function VoucherList({ vouchers, handleItemClick, isRedeemed }) {
  return (
    <div className="py-3">
      {vouchers.map((voucher, index) => {
        return (
          <VoucherListItem
            key={index}
            voucher={voucher}
            onClick={handleItemClick ? () => handleItemClick(voucher) : null}
            isRedeemed={isRedeemed}
          />
        );
      })}
    </div>
  );
}

function VoucherListItem({ voucher, onClick, isRedeemed }) {
  const iconClass = !isRedeemed
    ? "fas fa-chevron-circle-right"
    : "fas fa-check-circle";

  return (
    <Card id={voucher.id} onClick={onClick} className="shadow mb-4">
      <div
        className="d-flex bg-size-cover rounded"
        style={{ backgroundImage: voucher.imgSrc }}
      >
        <CardBody
          className="rounded-left"
          style={{ backgroundColor: "#ffffff94" }}
        >
          <VoucherHeader voucher={voucher} isRedeemed={isRedeemed} />
        </CardBody>
        <div
          className={`bg-${voucher.color} shadow px-4 rounded-right d-flex align-items-center`}
        >
          <div className="text-white h2 mb-0">
            <i className={iconClass}></i>
          </div>
        </div>
      </div>
    </Card>
  );
}
