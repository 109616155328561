import React from "react";
import { Button } from "reactstrap";

import View from "./View";

export default function EmptyStateView({ isRedeemed, history, children }) {
  const iconClassName = isRedeemed
    ? "fas fa-folder-open"
    : "fas fa-check-circle";
  const navigationTarget = isRedeemed ? "/open" : "/redeemed";
  const headline = isRedeemed
    ? "Keine Eingelösten Gutscheine"
    : "Alle Gutscheine eingelöst";
  const message = isRedeemed
    ? "Es sieht so aus als hättest du noch keine Gutscheine eingelöst, Sändi! :("
    : "Es sieht so aus als hättest du alle Gutscheine eingelöst, Sändi! :)";
  const buttonText = isRedeemed ? "Stöbern" : "Anschauen";

  return (
    <View>
      {children}
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="pb-5 text-center">
          <div
            className="text-muted"
            style={{ fontSize: "7.5rem", opacity: "0.34" }}
          >
            <i className={iconClassName} />
          </div>
          <h2 className="text-primary">{headline}</h2>
          <p className="mb-4">{message}</p>
          <Button
            color="primary"
            onClick={() => {
              history.push(navigationTarget);
            }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </View>
  );
}
