import React from "react";

import View from "./View";

export default function Spinner({ className, style }) {
  return (
    <div
      className={`spinner-border small ${className}`}
      style={style}
      role="status"
    >
      <span className="sr-only">Lädt...</span>
    </div>
  );
}

export function LoadingView() {
  return (
    <View>
      <div className="h-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    </View>
  );
}
