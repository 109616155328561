export function getOpenVouchers(vouchers, redeemedVoucherObjects) {
  if (redeemedVoucherObjects === null) {
    return null;
  }
  if (!redeemedVoucherObjects) {
    return [];
  }

  const redeemedVoucherIds = getRedeemedVoucherIds(redeemedVoucherObjects);

  return vouchers.filter(voucher => {
    return !redeemedVoucherIds.includes(voucher.id);
  });
}

export function getRedeemedVouchers(vouchers, redeemedVoucherObjects) {
  if (redeemedVoucherObjects === null) {
    return null;
  }
  if (!redeemedVoucherObjects) {
    return [];
  }

  const redeemedVoucherIds = getRedeemedVoucherIds(redeemedVoucherObjects);

  let redeemedVouchers = vouchers.filter(voucher => {
    return redeemedVoucherIds.includes(voucher.id);
  });

  redeemedVouchers = redeemedVouchers.map(voucher => {
    const redeemedVoucherObject = redeemedVoucherObjects.find(voucherObject => {
      return voucherObject.id === voucher.id;
    });
    voucher.date = redeemedVoucherObject.date;
    voucher.timestamp = redeemedVoucherObject.timestamp;

    return voucher;
  });

  redeemedVouchers.sort((a, b) => b.timestamp - a.timestamp);

  return redeemedVouchers;
}

function getRedeemedVoucherIds(redeemedVoucherObjects) {
  return redeemedVoucherObjects.map(voucher => {
    return voucher.id;
  });
}
