import React, { useEffect, useState } from "react";
import { Modal, Button } from "reactstrap";

import View from "./View";
import VoucherList from "./VoucherList";
import VoucherModal from "./VoucherModal";
import EmptyStateView from "./EmptyStateView";
import { LoadingView } from "./Spinner";

export default function OpenVouchersView({
  history,
  fetchRedeemedVoucherObjects,
  pushRedeemedVoucher,
  openVouchers
}) {
  useEffect(() => {
    fetchRedeemedVoucherObjects();
  }, [fetchRedeemedVoucherObjects]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalVoucher, setModalVoucher] = useState({});
  const openModalWithVoucher = voucher => {
    setModalVoucher(voucher);
    setModalIsOpen(true);
  };

  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [successModalVoucher, setSuccessModalVoucher] = useState({});
  const openSuccessModalWithVoucher = voucher => {
    setSuccessModalVoucher(voucher);
    setSuccessModalIsOpen(true);
  };

  if (openVouchers === null) {
    return <LoadingView />;
  }

  if (openVouchers.length === 0) {
    return (
      <EmptyStateView history={history}>
        <SuccessModal
          isOpen={successModalIsOpen}
          setIsOpen={setSuccessModalIsOpen}
          voucher={successModalVoucher}
        />
      </EmptyStateView>
    );
  }

  return (
    <View>
      <h4>
        <span>Offene</span> <span className="text-muted">Gutscheine</span>
      </h4>
      <SuccessModal
        isOpen={successModalIsOpen}
        setIsOpen={setSuccessModalIsOpen}
        voucher={successModalVoucher}
      />
      <VoucherModal
        isOpen={modalIsOpen}
        voucher={modalVoucher}
        setIsOpen={setModalIsOpen}
        pushRedeemedVoucher={pushRedeemedVoucher}
        openSuccessModalWithVoucher={openSuccessModalWithVoucher}
      />
      <VoucherList
        handleItemClick={openModalWithVoucher}
        vouchers={openVouchers}
      ></VoucherList>
    </View>
  );
}

function SuccessModal({ isOpen, setIsOpen, voucher }) {
  return (
    <Modal isOpen={isOpen} id="success-modal" centered contentClassName="mb-5">
      <div className={`rounded-top p-3 text-center text-white bg-success`}>
        <div className="h2 mt-2 text-white">
          <i className="fas fa-check-circle"></i>
        </div>
      </div>
      <div className="text-center p-3 bg-light">
        <h2 className="mb-3">{voucher.label}</h2>
        <p className="mb-0 mx-5">
          Oki sehr gut, du hast den Gutschein eingelöst, Sändi! :)
        </p>
      </div>
      <div className="p-3 text-center rounded-bottom shadow">
        <Button
          color="success"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Schließen
        </Button>
      </div>
    </Modal>
  );
}
