import React, { useEffect, useState } from "react";

import View from "./View";
import VoucherList from "./VoucherList";
import VoucherModal from "./VoucherModal";
import EmptyStateView from "./EmptyStateView";
import { LoadingView } from "./Spinner";

export default function RedeemedVouchersView({
  history,
  fetchRedeemedVoucherObjects,
  redeemedVouchers
}) {
  useEffect(() => {
    fetchRedeemedVoucherObjects();
  }, [fetchRedeemedVoucherObjects]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalVoucher, setModalVoucher] = useState({});
  const openModalWithVoucher = voucher => {
    setModalVoucher(voucher);
    setModalIsOpen(true);
  };

  if (redeemedVouchers === null) {
    return <LoadingView />;
  }

  if (redeemedVouchers.length === 0) {
    return <EmptyStateView history={history} isRedeemed />;
  }

  return (
    <View>
      <h4>
        <span>Eingelöste</span> <span className="text-muted">Gutscheine</span>
      </h4>
      <VoucherModal
        isOpen={modalIsOpen}
        voucher={modalVoucher}
        isRedeemed
        setIsOpen={setModalIsOpen}
      />
      <VoucherList
        vouchers={redeemedVouchers}
        handleItemClick={openModalWithVoucher}
        isRedeemed={true}
      />
    </View>
  );
}
