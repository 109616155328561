export async function getRedeemedVouchers() {
  const response = await fetch("/api/redeemed/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
  const data = await response.json();

  return { ok: response.ok, data };
}

export async function addRedeemedVoucher(id) {
  const response = await fetch("/api/redeemed/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ id })
  });
  const data = await response.json();

  return { ok: response.ok, data };
}

export async function deleteRedeemedVoucher(id) {
  const response = await fetch("/api/redeemed/", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ id })
  });
  const data = await response.json();

  return { ok: response.ok, data };
}
