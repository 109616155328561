import React, { useState, useCallback } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";

import "./App.scss";

import StartView from "./StartView";
import OpenVouchersView from "./OpenVouchersView";
import RedeemedVouchersView from "./RedeemedVouchersView";
import AdminView from "./AdminView";
import { getOpenVouchers, getRedeemedVouchers } from "./voucher";
import Spinner from "./Spinner";

import {
  getRedeemedVouchers as getRedeemedVouchersApiRequest,
  addRedeemedVoucher as addRedeemedVoucherApiRequest,
  deleteRedeemedVoucher as deleteRedeemedVoucherApiRequest
} from "./api";
import VOUCHERS from "./voucher-data";

const ROUTES = [
  { label: "Start", path: "/", component: StartView, icon: "fas fa-home" },
  {
    label: "Offen",
    path: "/open",
    component: OpenVouchersView,
    icon: "fas fa-clipboard-list"
  },
  {
    label: "Eingelöst",
    path: "/redeemed",
    component: RedeemedVouchersView,
    icon: "fas fa-check-circle"
  }
];

export default function App() {
  let history = useHistory();

  const [redeemedVoucherObjects, setRedeemedVouchers] = useState(null);

  const memoizedFetchRedeemedVoucherObjects = useCallback(async () => {
    const response = await getRedeemedVouchersApiRequest();
    setRedeemedVouchers(response.data);

    return response;
  }, [setRedeemedVouchers]);
  async function pushRedeemedVoucher(id) {
    const response = await addRedeemedVoucherApiRequest(id);
    setRedeemedVouchers(response.data);

    return response;
  }
  async function deleteRedeemedVoucher(id) {
    const response = await deleteRedeemedVoucherApiRequest(id);
    setRedeemedVouchers(response.data);

    return response;
  }

  const openVouchers = getOpenVouchers(VOUCHERS, redeemedVoucherObjects);
  const redeemedVouchers = getRedeemedVouchers(
    VOUCHERS,
    redeemedVoucherObjects
  );

  return (
    <>
      <Navbar
        light
        fixed="top"
        color="light"
        style={{ height: "45px" }}
        className="shadow-sm py-0"
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <NavbarBrand
            onClick={() => {
              history.push("/");
            }}
            className="p-0"
            style={{ fontSize: "1.5rem" }}
          >
            <i className="fas fa-gift" />
          </NavbarBrand>
          <span className="navbar-text py-0">
            <VoucherCounter openVouchers={openVouchers} history={history} />
          </span>
        </div>
      </Navbar>
      <Switch>
        <Route exact path="/">
          <StartView
            history={history}
            fetchRedeemedVoucherObjects={memoizedFetchRedeemedVoucherObjects}
            redeemedVouchers={redeemedVouchers}
            openVouchers={openVouchers}
          />
        </Route>
        <Route path="/open">
          <OpenVouchersView
            history={history}
            fetchRedeemedVoucherObjects={memoizedFetchRedeemedVoucherObjects}
            pushRedeemedVoucher={pushRedeemedVoucher}
            openVouchers={openVouchers}
          />
        </Route>
        <Route path="/redeemed">
          <RedeemedVouchersView
            history={history}
            fetchRedeemedVoucherObjects={memoizedFetchRedeemedVoucherObjects}
            deleteRedeemedVoucher={deleteRedeemedVoucher}
            redeemedVouchers={redeemedVouchers}
          />
        </Route>
        <Route path="/admin">
          <AdminView
            history={history}
            fetchRedeemedVoucherObjects={memoizedFetchRedeemedVoucherObjects}
            deleteRedeemedVoucher={deleteRedeemedVoucher}
            redeemedVouchers={redeemedVouchers}
          />
        </Route>
      </Switch>
      <Navbar
        dark
        fixed="bottom"
        color="primary"
        expand="xs"
        className="shadow p-1"
        style={{ height: "60px" }}
      >
        <Nav navbar className="w-100 justify-content-around">
          {ROUTES.map((route, index) => (
            <AppNavItem key={index} history={history} route={route} />
          ))}
        </Nav>
      </Navbar>
    </>
  );
}

function VoucherCounter({ openVouchers, history }) {
  if (openVouchers === null) {
    return <Spinner style={{ width: "20px", height: "20px" }} />;
  }

  const count = openVouchers.length;
  const text = count > 0 ? "Offen" : "Alle eingelöst";
  const badgeContent = count > 0 ? count : <i className="fas fa-check" />;
  const navTarget = count > 0 ? "/open" : "/redeemed";

  return (
    <div
      style={{ fontSize: "1.25rem" }}
      onClick={() => {
        history.push(navTarget);
      }}
    >
      {text}
      <span className="ml-2 badge bg-gray badge-pill">{badgeContent}</span>
    </div>
  );
}

function AppNavItem({ history, route }) {
  const pathname = window.location.pathname;

  return (
    <NavItem
      className={pathname === route.path ? "active" : ""}
      style={{ width: "120px" }}
    >
      <NavLink
        onClick={() => {
          history.push(route.path);
        }}
        className="text-center py-1 transition-base cursor-pointer"
      >
        <div className="mb-n1" style={{ fontSize: "1.5rem" }}>
          <i className={route.icon}></i>
        </div>
        <div>{route.label}</div>
      </NavLink>
    </NavItem>
  );
}
