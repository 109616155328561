import React from "react";

import VoucherBadge from "./VoucherBadge";

export default function VoucherHeader({
  voucher,
  isRedeemed,
  withLocationHref
}) {
  const href = withLocationHref ? voucher.locationHref : null;
  const onClick = withLocationHref
    ? e => {
        e.stopPropagation();
      }
    : null;

  return (
    <>
      <h3 className={`text-${voucher.color} mb-2`}>{voucher.label}</h3>
      <h6 className="text-muted mb-1">
        <a href={href} onClick={onClick} className="text-muted">
          <i className={`fas fa-map-marker-alt mr-2 small`}></i>
          {voucher.location}
        </a>
        {false && (
          <>
            <br />
            <i className="fas fa-calendar-check mr-2 small" />
            {voucher.date}
          </>
        )}
      </h6>
      {isRedeemed && (
        <>
          <h6 className={`text-${voucher.color} mb-1`}>
            <i className="fas fa-calendar-check mr-2 small" />
            {voucher.date}
          </h6>
          <VoucherBadge isRedeemed />
        </>
      )}
      {!isRedeemed && <VoucherBadge color={voucher.color} />}
    </>
  );
}
