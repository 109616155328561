import React, { useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import View from "./View";
import Spinner from "./Spinner";

export default function StartView({
  history,
  redeemedVouchers,
  openVouchers,
  fetchRedeemedVoucherObjects
}) {
  useEffect(() => {
    fetchRedeemedVoucherObjects();
  }, [fetchRedeemedVoucherObjects]);

  const redeemedIconClass = "fas fa-check-circle";
  const openIconClass = "fas fa-clipboard-list";

  const openVouchersCount = openVouchers ? openVouchers.length : null;
  const redeemedVouchersCount = redeemedVouchers
    ? redeemedVouchers.length
    : null;

  return (
    <View>
      <h4>Start</h4>
      <Row className="py-3 mb-2 mx-n2">
        <Col xs="6" className="px-2">
          <StatsCard
            onClick={() => {
              history.push("/open");
            }}
            label="Offene"
            number={openVouchersCount}
            iconClass={openIconClass}
            color="warning"
          />
        </Col>
        <Col xs="6" className="px-2">
          <StatsCard
            onClick={() => {
              history.push("/redeemed");
            }}
            label="Eingelöste"
            number={redeemedVouchersCount}
            iconClass={redeemedIconClass}
            color="primary"
          />
        </Col>
      </Row>
      <div>
        <BirthdayCard />
      </div>
      <div className="text-right py-2">
        <small>
          <strong>2020</strong> made with{" "}
          <i className="fas fa-heart text-secondary"></i> for Sandra
        </small>
      </div>
    </View>
  );
}

function StatsCard({ color, label, number, iconClass, onClick }) {
  const numberElement =
    number !== null ? (
      <h2 className={`mb-0 text-${color}`}>{number}</h2>
    ) : (
      <Spinner />
    );

  return (
    <Card className="shadow bg-light" onClick={onClick}>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <h5>
              <strong>{label}</strong>
              <br />
              <span className="small">Gutscheine</span>
            </h5>
            {numberElement}
          </div>
          <div>
            <div className={`h5 text-${color}`}>
              <i className={iconClass}></i>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

const CARD_TEXT = (
  <>
    <p>
      mit dieser Aussicht hat irgendwie alles so richtig angefangen. Hier haben
      wir angefangen Dinge zu unternehmen und ich würd das gern so weiter
      machen. Deswegen hab ich mal ein paar Dinge aufgeschrieben, die ich gerne
      mit dir machen würde.
    </p>
    <p>
      <strong>Alles Gute</strong> zum Geburtstag, Sändi! :)
    </p>
  </>
);

function BirthdayCard() {
  return (
    <Card>
      <div>
        <img
          className="w-100"
          src="/img/IMG_1232_350w.jpg"
          alt="Hassel Rooftop"
        />
      </div>
      <CardBody className="bg-light">
        <h4 className="mb-1 card-title">
          Liebe <span className="text-primary">Sandra</span>
        </h4>
        <div className="card-text">{CARD_TEXT}</div>
        <h6 className="card-subtitle mb-3">
          Viel Spaß mit <span className="text-primary">deiner</span> App :)
        </h6>
        <div className="d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-center">
            <div
              className="rounded-circle shadow-lg mr-2"
              style={{
                width: "30px",
                overflow: "hidden",
                filter: "grayscale(65%)"
              }}
            >
              <img
                className="w-100"
                src="/img/IMG_1602_cropped_130w.jpg"
                alt="Profile"
              />
            </div>
            <h5 className="mb-0">
              <span className="small">Puumi</span>
            </h5>
          </div>
          <div className="h5 mb-0">
            <i className="fas fa-gift" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
