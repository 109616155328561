import React from "react";
import { Button, Modal } from "reactstrap";

import VoucherHeader from "./VoucherHeader";

export default function VoucherModal({
  isOpen,
  voucher,
  isRedeemed,
  setIsOpen,
  pushRedeemedVoucher,
  openSuccessModalWithVoucher
}) {
  const closeButtonText = !isRedeemed ? "Abbrechen" : "Schließen";

  return (
    <Modal isOpen={isOpen} centered contentClassName="mb-5">
      <div className="p-3">
        <VoucherHeader
          voucher={voucher}
          isRedeemed={isRedeemed}
          withLocationHref
        />
      </div>
      <div className="p-3 bg-light overthrow" style={{ maxHeight: "300px" }}>
        {voucher.description}
      </div>
      <div className="text-right p-3 rounded-bottom shadow">
        {!isRedeemed && (
          <>
            <Button
              color={voucher.color}
              outline
              onClick={async () => {
                const response = await pushRedeemedVoucher(voucher.id);

                if (response.ok === true) {
                  setIsOpen(false);
                  openSuccessModalWithVoucher(voucher);
                }
              }}
            >
              Einlösen
            </Button>{" "}
          </>
        )}
        <Button
          color={voucher.color}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {closeButtonText}
        </Button>
      </div>
    </Modal>
  );
}
