import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";

import View from "./View";
import VoucherList from "./VoucherList";
import { LoadingView } from "./Spinner";
import VoucherHeader from "./VoucherHeader";

export default function AdminView({
  fetchRedeemedVoucherObjects,
  deleteRedeemedVoucher,
  redeemedVouchers
}) {
  useEffect(() => {
    fetchRedeemedVoucherObjects();
  }, [fetchRedeemedVoucherObjects]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalVoucher, setModalVoucher] = useState({});
  const openModalWithVoucher = voucher => {
    setModalVoucher(voucher);
    setModalIsOpen(true);
  };

  if (redeemedVouchers === null) {
    return <LoadingView />;
  }

  return (
    <View className="bg-gray">
      <h4>
        <span className="text-danger">Admin View</span>
      </h4>
      <Modal isOpen={modalIsOpen} centered contentClassName="mb-5">
        <div className="p-3">
          <VoucherHeader
            voucher={modalVoucher}
            isRedeemed={true}
            withLocationHref
          />
        </div>
        <div className="p-3 bg-light">
          <h2>Gutschein wirklich zurücksetzen?</h2>
        </div>
        <div className="text-right p-3 rounded-bottom shadow">
          <Button
            color="danger"
            outline
            onClick={() => {
              deleteRedeemedVoucher(modalVoucher.id);
              setModalIsOpen(false);
            }}
          >
            Zurücksetzen
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              setModalIsOpen(false);
            }}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>
      <VoucherList
        vouchers={redeemedVouchers}
        handleItemClick={openModalWithVoucher}
        isRedeemed={true}
      />
    </View>
  );
}
