import React from "react";

export default function VoucherBadge({ color, className, isRedeemed }) {
  const badgeClassName = color ? `badge-${color}` : "bg-gray";
  const text = !isRedeemed ? "Offen" : "Eingelöst";

  return (
    <span className={`badge ${badgeClassName} px-2 ${className}`}>{text}</span>
  );
}
