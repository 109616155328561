import React from "react";

const imgSources = [
  `url("/img/3132_16x9_700w.jpg")`,
  `url("/img/3305340_16x9_700w.jpg")`,
  `url("/img/3334702_16x9_700w.jpg")`,
  `url("/img/white-polygonal-abstract-mosaic-background_108964-439.jpg")`
];

const domTourDates = [
  { dayData: "Freitag", timeData: "16.00 Uhr" },
  { dayData: "Samstag", timeData: "15.00 Uhr" },
  { dayData: "Sonntag", timeData: "12.00 Uhr" }
];

const albinMuellerOpeningHours = [
  { dayData: "Dienstag - Freitag", timeData: "11.00 - 17.00 Uhr" },
  { dayData: "Wochenende und Feiertage", timeData: "12.00 - 18.00 Uhr" }
];

const domKoenigOpeningHours = [
  {
    dayData: "Dienstag - Donnerstag",
    timeData: "11.00 - 24.00 Uhr",
    additional: "(Küchenschluss 22 Uhr)"
  },
  {
    dayData: "Freitag und Samstag",
    timeData: "11.00 - 01.00 Uhr",
    additional: "(Küchenschluss 23 Uhr)"
  },
  {
    dayData: "Sonntag",
    timeData: "11.00 - 22.00 Uhr",
    additional: "(Küchenschluss 20 Uhr)"
  }
];

export default [
  {
    id: 0,
    label: (
      <>
        Demo
        <br />
        Gutschein
      </>
    ),
    color: "info",
    imgSrc: imgSources[2],
    location: "Puumi's Wohnung",
    locationHref: "https://goo.gl/maps/wpeyBqL41zwwmWnd7",
    description: (
      <>
        <p>
          Hier stehen immer Infos zum Gutschein wie eine{" "}
          <strong>Erklärung</strong> der Aktivität und wann man sie{" "}
          <strong>zeitlich</strong> machen kann.
        </p>
        <p>
          Beim Klick auf die <strong>Location</strong> oben wird die hinterlegte
          Adresse in Google Maps geöffnet.
        </p>
        <p>
          Beim Klick auf <strong>Einlösen</strong> unten wird der Gutschein
          eingelöst. Es werden Datum und Uhrzeit der Einlösung gespeichert und
          bei eingelösten Gutscheinen angezeigt.
        </p>
      </>
    )
  },
  {
    id: 1,
    label: (
      <>
        Dom
        <br />
        Besteigung
      </>
    ),
    color: "primary",
    imgSrc: imgSources[0],
    location: "Dom zu Magdeburg",
    locationHref: "https://goo.gl/maps/9T73ZaRLbBoQwBib9",
    description: (
      <>
        <p>
          Es ging ja schon länger nicht mehr so richtig hoch hinaus und deswegen
          würde ich gerne mit dir den Dom besteigen. Von <strong>April</strong>{" "}
          bis <strong>Oktober</strong> 2020 werden wieder Dom-Turmführungen
          angeboten.
        </p>
        <p>
          Die angebotenen <strong>Termine</strong> sind
        </p>
        <OpeningHours openingHours={domTourDates} color="primary" noLineBreak />
      </>
    )
  },
  {
    id: 2,
    label: <>Frühstück im Café Alt Magdeburg</>,
    color: "secondary",
    imgSrc: imgSources[1],
    location: "Hundertwasserhaus",
    locationHref: "https://goo.gl/maps/3QhsyqNko9FVt1JH9",
    description: (
      <>
        <p>
          Das <strong>Café Alt Magdeburg</strong> ist das Café im
          Hundertwasserhaus mit den ganzen alten Ansichtsbildern von Magdeburg,
          über das wir uns letztens unterhalten haben. Eigentlich wollten wir ja
          auch schon vor einiger Zeit mal <strong>gemeinsam</strong>{" "}
          Frühstücken.
        </p>
        <p>
          Frühstück bieten sie <span className="text-secondary">täglich</span>{" "}
          von <span className="font-weight-bold">08.00 - 12.00 Uhr</span>.
        </p>
      </>
    )
  },
  {
    id: 3,
    label: <>Albin-Müller-Turm Besteigung</>,
    color: "warning",
    imgSrc: imgSources[2],
    location: "Rothehornpark",
    locationHref: "https://goo.gl/maps/peiVHaiNJwEPekLq5",
    description: (
      <>
        <p className="">
          Die <strong>Öffnungszeiten</strong> im Internet sind
        </p>
        <OpeningHours openingHours={albinMuellerOpeningHours} color="warning" />
        <p>aber da muss man ja bekanntlich vorsichtig sein.</p>
        <p>
          Vielleicht gehen wir ja ab <strong>Frühling</strong> häufiger mal im
          Stadtpark laufen und erhöhen mit mehreren Anläufen die
          Wahrscheinlichkeit, dass es klappt.
        </p>
      </>
    )
  },
  {
    id: 4,
    label: (
      <>
        Mädchen-
        <br />
        Schnulli-Film
      </>
    ),
    color: "info",
    imgSrc: imgSources[3],
    location: "Moritzhof/Studiokino",
    locationHref: "https://goo.gl/maps/XLXgVtsQZBrNh8rm7",
    description: (
      <>
        <p>
          Ich finde wir könnten dem <strong>Moritzhof</strong> mal wieder einen
          Besuch abstatten. Und um dir alle Zweifel zu nehmen, ob du mich in
          einen solchen Film mitnehmen solltest, gibt's hierfür einen Gutschein.
        </p>
        <div className="mb-3">
          <strong>Moritzhof</strong>
          <div className="my-2">
            <a
              className="btn btn-info btn-sm"
              href="https://moritzhof-magdeburg.de/kino-programm/"
            >
              Programm heute
            </a>{" "}
            <a
              className="px-2 text-info"
              href="https://moritzhof-magdeburg.de/filme/"
            >
              Alle Filme
            </a>
          </div>
        </div>
        <div className="mb-3">
          <strong>Studiokino</strong>
          <div className="my-2">
            <a
              className="btn btn-info btn-sm"
              href="https://www.studiokino.com/"
            >
              Programm heute
            </a>{" "}
            <a
              className="px-2 text-info"
              href="https://www.studiokino.com/filme"
            >
              Alle Filme
            </a>
          </div>
        </div>
      </>
    )
  },
  {
    id: 5,
    label: (
      <>
        LesMills
        <br />
        Sportkurs
      </>
    ),
    color: "primary",
    imgSrc: imgSources[1],
    location: "Gesundheitszentrum MD",
    locationHref: "https://goo.gl/maps/HQbCJZcccQp28QiC9",
    description: (
      <>
        <p>
          Du kannst zwischen folgenden <strong>Kursen</strong> wählen:
        </p>
        <ul className="pl-4">
          <li>
            <span className="text-primary">Bodybalance</span> (
            <strong>60 Minuten</strong>)<br />
            Steigert Beweglichkeit, Gleichgewicht und Ausgeglichenheit.
            Reduziert Stress.
          </li>
          <li>
            <span className="text-primary">Grit</span> (
            <strong>45 Minuten</strong>)<br />
            Maximale Trainingsergebnisse in nur 30 Minuten. Hochintensives
            Intervalltraining (HIIT) mit 3 Trainingsschwerpunkten: Strength,
            Cardio, Athletic.
          </li>
          <li>
            <span className="text-primary">CXWORX</span> (
            <strong>30 Minuten</strong>)<br />
            Definiert und stärkt Bauch- und Rückenmuskulatur. Verbessert
            funktionale Kraft und Beweglichkeit.
          </li>
        </ul>
        <p>
          Wenn du dich entschieden hast, können wir spontan und bequem über die
          von <strong>CodeGewerk.</strong> eingerichtete Platform den Kurs
          buchen.
        </p>
      </>
    )
  },
  {
    id: 6,
    label: (
      <>
        Dinner
        <br />
        Date
      </>
    ),
    color: "secondary",
    imgSrc: imgSources[2],
    location: "Domkönig",
    locationHref: "https://goo.gl/maps/E4Vu4zwfBbExjW65A",
    description: (
      <>
        <p>
          Ich weiß gar nicht, wie du Essen gehen eigentlich findest, aber ich
          wollte das schon immer mal mit <strong>dir</strong> machen. Außerdem
          wollte ich schon immer mal das neue Restaurant{" "}
          <strong>Domkönig</strong> ausprobieren.
        </p>
        <p>
          Die <strong>Öffnungszeiten</strong> sind
        </p>
        <OpeningHours openingHours={domKoenigOpeningHours} color="secondary" />
      </>
    )
  }
];

function OpeningHours({ openingHours, color, noLineBreak }) {
  return (
    <ul className="pl-4">
      {openingHours.map((openingHourItem, index) => (
        <li key={index}>
          <span className={`text-${color}`}>{openingHourItem.dayData}</span>{" "}
          {!noLineBreak && <br />}
          <strong>{openingHourItem.timeData}</strong>
          {openingHourItem.additional && (
            <>
              <br />
              <span>{openingHourItem.additional}</span>
            </>
          )}
        </li>
      ))}
    </ul>
  );
}
